<template>
  <div class="w-full min-h-screen relative p-12 bg-white dark:bg-black">
    <masonry-layout :images="stream"/>
  </div>
</template>

<script>
import { createApi } from 'unsplash-js'
import MasonryLayout from '@/layouts/MasonryLayout'

export default {
  name: 'App',
  components: { MasonryLayout },
  data () {
    return {
      api: createApi({
        accessKey: process.env.VUE_APP_UNSPLASH_ACCESS_KEY
      }),
      stream: null,
    }
  },
  mounted () {

    // feed example
    this.api.users.getPhotos({ username: 'antonioleutsch', perPage: 50, }).then(result => {
      if (result.errors) {
        // handle error here
        console.log('error occurred: ', result.errors[0])
      } else {
        const feed = result.response

        // extract total and results array from response
        const { total, results } = feed

        this.stream = results

        // handle success here
        console.log(`received ${results.length} photos out of ${total}`)
        console.log('first photo: ', results[0])
      }
    })
  },
}
</script>
