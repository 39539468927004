<template>
  <intersect @enter.once="onEnter">
    <div class="relative">
      <!-- Show the placeholder as background -->
     <template v-if="orientation === 'portrait'">
       <blurhash-img
           :aspect-ratio="width / height"
           :hash="blurhash"
           class="absolute top-0 left-0 transition-opacity duration-500"
           :class="isLoaded ? 'opacity-0' : 'opacity-100'"
       />

     </template>
      <template v-else>
        <blurhash-img
            :aspect-ratio="height / width"
            :hash="blurhash"
            class="absolute top-0 left-0 transition-opacity duration-500"
            :class="isLoaded ? 'opacity-0' : 'opacity-100'"
        />
      </template>
      <!-- Show the real image on the top and fade in after loading -->
      <img
          ref="image"
          :width="width"
          :height="height"
          v-bind="$attrs"
          class="absolute top-0 left-0 transition-opacity duration-500"
          :class="isLoaded ? 'opacity-100' : 'opacity-0'"
      >
    </div>
  </intersect>
</template>

<script>
import BlurhashImg from './BlurhashImg'
import Intersect from 'vue-intersect'

export default {
  components: {
    Intersect,
    BlurhashImg
  },

  inheritAttrs: false,

  props: {
    src: {
      type: String,
      required: true
    },
    blurhash: {
      type: String,
      required: false,
      default: null,
    },
    width: {
      type: Number,
      default: 1
    },
    height: {
      type: Number,
      default: 1
    },
    orientation: {
      type: String,
      default: 'portait'
    }
  },

  data() {
    return {
      isVisible: false,
      isLoaded: false
    }
  },

  methods: {
    onEnter() {
      // Image is visible (means: has entered the viewport),
      // so start loading by setting the src attribute
      this.$refs.image.src = this.src

      this.$refs.image.onload = () => {
        // Image is loaded, so start fading in
        this.isLoaded = true
      }

    }
  }
}
</script>
