<template>
  <masonry
      :cols="{default: 4, 767: 2}"
      :gutter="{default: '24px', 1024: '32px'}"
  >
    <div v-for="(image, index) in images" class="my-4 lg:my-6" :key="index">
      <lazy-image
          :src="image.urls.regular"
          :blurhash="image.blur_hash"
          :width="image.width"
          :height="image.height"
          :orientation="image.orientation"
      />
    </div>
  </masonry>
</template>

<script>
import LazyImage from '@/components/LazyImage'
export default {
  name: 'MasonryLayout',
  components: { LazyImage },
  props: {
    images: {
      type: Array,
      default () {
        return []
      }
    },
  },
}
</script>
